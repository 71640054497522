.verde {
    color:#79AD36
}
.verde-escuro {
    color:#567A26
}
.bg-verde {
    background-color:#79AD36
}
.bg-verde-escuro {
    background-color:#567A26
}

.form-container {
    border:1px solid #D4D4D4;
    border-radius: 5px;
    overflow: hidden;
}
.form-container input, .form-container button {
    border:0;
    background: 0;
    height: 100%;
    font-size: 14px;
    color:#7A7A7A
}
.form-container input, .form-container input:focus {
    width:90%;
    float: left;
    outline: none;
}
.form-container button {
    width:10%;
    text-align: right;
    padding:8px 10px;
}

.btn {
    border:0;
    border-radius:5px !important;
    padding:8px 30px !important;
    font-weight: 700 !important;
}

.btn-verde {
    background-color: #79AD36 !important;
    color:#FFF !important;
}
.btn-verde:hover {
    background-color: #567A26 !important;
}
.btn-borda,.btn-borda:hover {
    border:2px solid #fff !important;
    color:#fff
}

.navbar {
    border-top:1px solid #D4D4D4
}
.navbar a {
    color:#222
}
.carousel img {
    height: 500px;
}
.carousel-caption {
    text-align: left !important;
    bottom:8vh;
}
.carousel-caption h3 {
    font-size:4vw;
    color:#FFF;
}
@media (max-width: 520px) {
    .carousel {
        height: 350px;
    }
    .carousel img {
        height: 350px;
        width:150% !important;
    }
    .carousel-caption h3 {
        font-size: 28px;
    }
    .carousel-caption {
        bottom: 10%;
    }
}
a.box {
    color:#333;
    text-decoration: none;
    display: block;
    border:3px solid #79AD36;
    border-radius: 5px;
    padding:20px 20px 10px 20px;
}
a.box:hover{
    background-color: #e3f1d1;
}
a.box img {
    margin-bottom: 10px;
}
#produtos a {
    color:#333;
    text-decoration: none !important;
}
#produtos h4 {
    font-size:18px;
}