body {
  margin: 0;
  font-family: open-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
}
b {
  font-family: open-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
}
h1, h2, h3, h4 {
  font-family: open-sans-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@import 'bootstrap/dist/css/bootstrap.min.css';
@import './assets/css/styles.css';


@import url('https://use.typekit.net/iwg2koa.css');